@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "GloomyThings";
    src: url("../src/fonts/GloomyThings.ttf");
  }

/* select { */
    /* background: url(../src/assets/ico_dropdown_arrow.svg) no-repeat; */
    /* -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    background-position-x: 95%
} */

.css-1pxa9xg-MuiAlert-message {
    font-family: "lato";
}

.css-1mwtzdp-MuiImageList-root {
    scrollbar-width: none;
}

.css-13cymwt-control {
    background-color: unset !important; 
    height: 24px;
}

.react-select-7-listbox {
    background-color: #1D1D1D;
}

div.image-tool-tip span {
    display: none;
    position: absolute;
    background: #AFAFAF;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #fff;
    border-radius: 5px;
  }
  
div.image-tool-tip:hover span {
    display: block;
}

.progress-step {}

.css-46bh2p-MuiCardContent-root:last-child {
    padding-bottom: 4px !important;
}

.css-46bh2p-MuiCardContent-root {
    padding: 8px !important;
}

.MuiImageListItem-img {
    height: 128px !important;
    width: 192px !important;
    object-fit : cover !important;
    border : black;
    border-width: 0.5px;
    border-style: solid;
    border-radius: 10px;
    display: block !important;
    -webkit-box-flex: unset !important;
    -webkit-box-grow: unset !important;
    flex-grow: unset !important;
}

.progress-step .progress-marker::after {
    background-color: #ffffff;
}

.progress-step .progress-marker::before {
    background-color: #ffffff;
    border: 3px solid #b6b6b6;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.progress-step.is-complete .progress-marker::before {
    background-color: #C7A33F;
    border: 3px solid #C7A33F;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.progress-step.is-active .progress-marker::before {
    background-color: white;
    border: 3px solid #C7A33F;
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.progress-step.is-active .progress-text .progress-title {
    color: #C7A33F;
    font-size: 16px;
}

.progress-step .progress-text .progress-title {
    color: #AFAFAF;
    font-size: 16px;
    padding: 0;
}

.progress-step.is-complete .progress-text .progress-title {
    color: #1D1D1D;
    font-size: 16px;
}

.progress-step.is-complete .progress-marker::after,
.progress-step.is-progress .progress-marker::after {
    background-color: #C7A33F;
}

.progress-tracker--vertical .progress-text {
    padding: 0px 10px 10px 20px;
    align-items: center;
}

.progress-tracker {
    height: 70vh;
}

/* .css-b62m3t-container,
.css-b62m3t-container div[class*='control'] {
    height: 100%;
} */

.css-b62m3t-container div[class*='control'] {
    /* padding-top: 6px; */
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
}

.css-b62m3t-container div[class*='control'] .css-1fdsijx-ValueContainer {
    padding: 0;
    height: 36px;
}

#tattoo-project-details {}

.css-1n6sfyn-MenuList> div img[src*="skin-tones"] {
    width: 60%;
    margin: 0 auto;
}

.css-b62m3t-container div[class*='control'] .css-1fdsijx-ValueContainer .css-1dimb5e-singleValue> div img {
    width: 60%;
    margin: 0 auto;
}

#more-tables tbody,
#more-tables tr,
#more-tables td,
#more-tables td {
    max-width: 100%;
    white-space: nowrap;
}

.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker__clear-button {
    display: none;
}

.react-date-picker__calendar-button {
    display: none;
}

.react-date-picker__inputGroup {
    font-size: medium;
    font-family: sans-serif;
    color: #AFAFAF;
}

#priceFrom {
    background: none;
}

#priceTo {
    background: none;
}

#search {
    background: none;
}

.ant-btn span {
    color: unset;
}

.swal2-cancel {
    background-color: #ffffff !important;
    border: 1px solid #C7A33F !important;
    color: #000000 !important;
}

.swal2-confirm {
    background-color: #C7A33F !important;
    border: 1px solid #C7A33F !important;
}

#artistNameLabel {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    background-color: #ffffff;
    color: #000000;
}

.react-datepicker-wrapper {
    width: unset !important;
}

.carousel .thumb {
    padding: 0px;
}

.carousel .slide img {
    height: 100vh;
}

.carousel .thumb.selected {
    border-color: #C7A33F;
    border-width: 2px;
}

.carousel .thumbs-wrapper {
    margin: 10px !important;
}

.MuiTableCell-head {
    font-size: 14px !important;
    color: #AFAFAF !important;
    font-family: sans-serif;
    padding: 4px !important;
}

.MuiTableCell-body {
    padding: 4px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.css-1jxourk {
    max-width: unset !important;
}

.react-date-picker__inputGroup {
    font-size: 14px;
    font-weight: 400;
}

@media (max-width: 320px) {

    #modelboardDraw div div {
        width: 393px !important;
        height: 402px !important;
    }

    #modelboardDraw div div canvas {
        width: 287px !important;
        height: 402px !important;
    }

    #modelboard canvas {
        height: 400px !important;
    }

    /* #tweakpanecontainer.mobile-customization .initial-hide {
        display: none;
    }

    #tweakpanecontainer.mobile-customization .initial-hide.active {
        display: block;
    } */

    .carousel .thumb {
        width : 40px !important;
    }

    #more-tables tbody,
    #more-tables tr,
    #more-tables td {
        display: block;
    }

    #more-tables thead tr {
        position: absolute;
        top: -99999px;
        left: -9999px
    }

    #more-tables td {
        position: relative;
        padding-left: 22%;
        width: 100%;
        padding-bottom: 10px;
    }

    #more-tables td:before {
        content: attr(data-title);
        position: absolute;
        left: 6px;
        font-weight: light;
        font-size: medium;
        color: #AFAFAF;
    }

    #more-tables tr {
        border: 1px solid ccc;
    }
}

@media (min-width:321px) and (max-width: 375px) {

    #modelboardDraw div div {
        width: 393px !important;
        height: 402px !important;
    }

    #modelboardDraw div div canvas {
        width: 340px !important;
        height: 402px !important;
    }

    #modelboard canvas {
        height: 400px !important;
    }

    /* #tweakpanecontainer.mobile-customization .initial-hide {
        display: none;
    }

    #tweakpanecontainer.mobile-customization .initial-hide.active {
        display: block;
    } */

    #more-tables tbody,
    #more-tables tr,
    #more-tables td {
        display: block;
    }

    #more-tables thead tr {
        position: absolute;
        top: -99999px;
        left: -9999px
    }

    #more-tables td {
        position: relative;
        padding-left: 20%;
        width: 100%;
        padding-bottom: 10px;
    }

    #more-tables td:before {
        content: attr(data-title);
        position: absolute;
        left: 6px;
        font-weight: light;
        font-size: medium;
        color: #AFAFAF;
    }

    #more-tables tr {
        border: 1px solid ccc;
    }

    .carousel .thumb {
        width : 50px !important;
    }
}

@media (min-width:376px ) and (max-width: 640px){
    
    #modelboardDraw div div {
        width: 393px !important;
        height: 402px !important;
    }

    #modelboardDraw div div canvas {
        width: 393px !important;
        height: 402px !important;
    }

    #modelboard canvas {
        height: 400px !important;
    }

    /* #tweakpanecontainer.mobile-customization .initial-hide {
        display: none;
    }

    #tweakpanecontainer.mobile-customization .initial-hide.active {
        display: block;
    } */

    #more-tables tbody,
    #more-tables tr,
    #more-tables td {
        display: block;
    }

    #more-tables thead tr {
        position: absolute;
        top: -99999px;
        left: -9999px
    }

    #more-tables td {
        position: relative;
        padding-left: 20%;
        width: 100%;
        padding-bottom: 10px;
    }

    #more-tables td:before {
        content: attr(data-title);
        position: absolute;
        left: 6px;
        font-weight: light;
        font-size: medium;
        color: #AFAFAF;
    }

    #more-tables tr {
        border: 1px solid ccc;
    }

    .carousel .thumb {
        width : 60px !important;
    }
}