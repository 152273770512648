* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  html,
  body,
  #root {
    min-height: 100%;
    height: 100%;
    font-family: system-ui;
    /* background: linear-gradient(rgba(0, 0, 0, 0.6), white); */
    --tp-base-background-color: hsla(0, 0%, 100%, 0.8);
    --tp-base-shadow-color: hsl(0, 5%, 96%);


    --tp-input-background-color: hsla(0, 0%, 0%, 0.3);
    --tp-input-foreground-color: #C7A33F !important;
    --tp-button-background-color: #C7A33F !important;

  }

  main {
    height: 100%;
  }
  
  canvas {
    height: 100%;
  }
  .buttongroup {
    position: fixed;
    inset: 0;
    top: 20px;
    left: 20px;
    display: flex;
    gap: 20px;
    z-index: 999;
    width: fit-content;
    height: fit-content;
  }
  
  .video {
    position: fixed;
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
  
  .button {
    align-items: center;
    appearance: none;
    background-color: #fcfcfd;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395a;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
  }
  
  .button:focus {
    box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  }
  
  .button:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button:active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
  }
  #drawing-canvas {
    position: fixed;
    background-color: #ffff;
    bottom: 0px;
    right: 0px;
    height: 128px;
    width: 128px;
    z-index: 3000;
    cursor: crosshair;
    touch-action: none;
  }

  .tp-rotv {
    border-radius: 0;
    box-shadow: 0 0 0 0;
    font-family: var(--font-family);
    font-size: 8px;
    font-weight: 400;
    line-height: 0.5;
    text-align: left;
    width: inherit;
  }

  /* Internal Text */
  .tp-lblv_l {
    /* visibility:hidden;
    width: 0%;
    display:none */
    /* min-width :max-content; */
    /* display: flex; */
    min-width:20%;
    font-size: 14px;
    font-family: "Lato";
    color: #000000 !important;
  }

  .tp-sldtxtv_t {
    visibility:hidden;
    width: 0%;
    display:none;
    font-size: 8px;
  }
  /* tp-tabv_c */
  .tp-brkv  {
    /* overflow: visible !important; */
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 0% !important;
  }
  .tp-tabv_c {
    border-left: 0px !important;
    border-radius: 0% !important;
  }
  /* .tp-lblv tp-v-fst tp-v-vfst {

  } */

  /* .tp-v-vfst {
    min-width:0%;
    max-width: 0%;
  } */



  /* .tp-tbiv_b:focus{
    min-width:fit-content;
  } */
  

  .tp-rotv.tp-rotv-not .tp-rotv_c>.tp-tabv.tp-v-fst>.tp-tabv_i {
    border-top-left-radius : 0px !important;
    border-top-right-radius : 0px !important;
  }


  .tp-rotv_c {
    overflow :visible !important;;
    border-radius: 0% !important;
  }

  .tp-tbiv {
    padding: 5px;
    background-color: #AFAFAF;
    color: #000000 !important;
    overflow: auto !important;
  }

  .tp-tbiv-sel {
    background-color: #C7A33F;
    color: #ffffff !important;
  }

  .tp-tbiv_b {
    /* margin-left: 10px; */
    background-color: inherit!important;
  }

  .tp-lblv {
    width: fit-content;
    min-width:100%;
    background-color: inherit;
    padding-bottom: 5px;
  }

  .tp-sldv_t {
    color: #C7A33F !important;
  }

  .tp-lblv_v {
    /* display: flex; */
    /* flex: 1; */
    min-width:80%
  }

  /* Tab Header */
  .tp-tbiv_t {
    font-size: 16px;
    font-family: "Lato";
    color: inherit !important;
  }

  .tp-tbiv_t-sel {
    color: #ffffff !important;
  }

  .css-13cymwt-control {
    border-width: 0px !important;
  }
  
  @media screen and (max-width: 480px) {
    .video {
      aspect-ratio: 9/16;
    }
    /* .tp-dfwv { */
      /* position: fixed !important; */
      /* width: 480px !important; */
      /* inset: 80% 0 !important; */
      /* width: 100% !important; */
      /* z-index: 9999999;
    } */
    /* .tp-lblv {
      flex-direction: column !important;
    } */
    /* .tp-lblv_l { */
        /* font-size: 8px;
        overflow: visible;
        padding-left: 0px;
        padding-right: 0px; */
        /* visibility: hidden; */
        /* width: 0%;
        padding: 0%;
      } */
    /* .tp-lblv_v {
      padding-left: 0%; */
      /* width: 100% !important; */
      /* display: block;
    } */
    /* .tp-sldtxtv_t {
        visibility: hidden;
    }
    .tp-brkv{
        overflow: hidden;
    }
    .tp-fldv_c {
        overflow: hidden;
    } */

    .tp-lblv_v {
      /* display: flex; */
      /* flex: 1; */
      min-width:60%
    }
  
  }
  
  