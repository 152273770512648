.pdf {
  font-family: sans-serif;
  text-align: center;
  padding: 20px;
  overflow-y: hidden;
}

.photo-viewer-container {
  width: 100% !important;
  height: 100% !important;
}

.photo-viewer-container>img {
  width: 100% !important;
  height: 100% !important;
}

.css-ql5uyh {
  top : 2% !important;
  left : 25% !important;
  width: 50% !important;
  height: 85% !important;
}

@media (max-width: 640px) { 
  .css-ql5uyh {
    top : 1% !important;
    left : 1% !important;
    width: 98% !important;
    height: 98% !important;
  }
}

